<template>
  <div id="main-wrapper">
    <div
      class="content-container profile-page personal-details request-call support-page"
    >
    <!-- Model Start -->
      <div class="modal fade" id="myModal" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content" style="margin-top:100px ">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Cancel Withdrawal Request</h4>
              </div>
              <div class="modal-body">
                <p>Are you sure that you want to cancel you Withdrawal Request</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-blue" data-dismiss="modal"  @click="cancelTicket(ticket.id,ticket.ticketnumber)">Process Request</button>
              </div>
            </div>
            
          </div>
      </div>
        <!-- Model End -->
      <div class="page-wrap">
        <div class="page-content clearfix">
          <div class="main-content f-left">
            <div class="title-holder wow fadeInLeft d-flex align-items-center justify-content-between content-holder">
              <h4>Open Tickets</h4>
            <button
              type="button"
              data-toggle="modal" data-target="#myModal"
              class="btn btn-blue"
              v-if="ticket.reason == '97' && reqforward">
              Cancel Withdrawal Request
            </button>
            </div>
            <div class="content-holder wow fadeInLeft">
              <div class="row-sec">
                <div class="form-holder">
                  <Form @submit="createThread">
                    <ul class="clearfix">
                      <li class="">
                        <label for="">Ticket Number</label>
                        <Field
                          type="text"
                          class="form-control"
                          name="ticket_id"
                          rules="required"
                          v-model="ticket.ticketnumber"
                          disabled
                        />
                      </li>
                      <li class="" v-if="ticket.reason">
                        <label for="">Reason</label>
                        <Field
                          class="form-control"
                          name="title_text"
                          rules="required"
                          v-model="store.ticketReasons[ticket.reason].title"
                          disabled
                        />
                      </li>
                      <li class="">
                        <label for="">Created</label>
                        <Field
                          class="form-control"
                          name="created"
                          v-model="ticket.created"
                          rules="required"
                          disabled
                        />
                      </li>
                      <li class="">
                        <label for="">Initial Comment</label>
                        <Field
                          as="textarea"
                          class="form-control"
                          rules="required"
                          name="comment"
                          v-model="ticket.comment"
                          disabled
                        />
                      </li>

                      <li class="">
                        <label for="">Message</label>
                        <div
                          v-if="
                            store.ticketDetails.threads &&
                            store.ticketDetails.threads.length
                          "
                          class="text-value2"
                        >
                          <div
                            v-for="thread of store.ticketDetails.threads"
                            class="chat clearfix"
                            :key="thread.id"
                          >
                          
                            
                            <div v-if="thread.docs.length > 0">
                              <div class="ticketDocs" v-for="threaddoc in thread.docs" :key="threaddoc.id">
                            <p class="f-left">
                              <strong
                                >{{
                                  thread.ticket_updatedby == 0
                                    ? "You"
                                    : "Admin"
                                }}:
                              </strong>
                              <span v-text="threaddoc.document_name"></span>
                            </p>
                            <p class="f-right">
                              {{ COMMON.formatDate(thread.created, true) }}
                            </p>
                            <a
                              target="_blank"
                              v-if="
                                ticketReasonUser()
                              "
                              :href="`${url}/get/docs?type=user&id=${store.user.profile.user_id}&doc=${threaddoc.document}`"
                              ><i class="fa fa-eye"></i
                            ></a>
                            <a
                              target="_blank"
                              v-if="
                                !ticketReasonUser()
                              "
                              :href="`${url}/get/docs?type=ticket&id=${store.user.profile.user_id}&doc=${threaddoc.document}`"
                              ><i class="fa fa-eye"></i
                            ></a>
                          </div>
                            </div>
                             <span v-if= "thread.message" v-html="thread.message" class="d-flex w-100"></span>
                             listing 
                          
                        </div>
                        </div>
                        
                      </li>
                      <li class="">
                        <label for="">Reply</label>
                        <Field
                          name="Message"
                          placeholder="Your reply"
                          rules="required"
                          v-model="replyMessage"
                        />
                        <ErrorMessage name="Message" />
                      </li>
                      <li class="">
                        <label for="">Attachment</label>
                        <div class="uploadShowMenu" :class="showDocList1 ? 'active' : ''">
                            <ul class="listAnimated setUpFxaccount">
                                <li class="bt1" v-for="val,index in static_vars.fileNames" :key="index">  
                                    <div class="sptIcon">
                                        <vue-feather size="16px" type="upload"></vue-feather>
                                    </div>
                                    <div class="upload-btn-wrapper" @click.prevent="docType = val; $refs.fileinput.click()" >
                                          <a class="btn"  href="#" @click.prevent="docType = val">{{val.value}}</a>
                                    </div>
                                </li>
                            </ul>   
                        </div>
                        <div class="file-upload">
                          <div class="file-upload-select multiUpload" v-if="parseInt(ticket.reason) == 91" @click.prevent="showDocList1 = !showDocList1">
                            <input
                              type="file"
                              name="file-upload-input"
                              accept="image/*,application/pdf"
                              class="file-upload-input"
                              disabled
                            />
                            <div class="file-select-name">
                              {{ attachment.name }}
                            </div>
                            <div class="file-select-button" v-show="!showDocList1">
                              <img
                                src="assets/images/file-upload-icon.png"
                                alt=""
                              />
                            </div>
                            <a href="javascript:void(0)" class="file-select-button filter-dropdown" v-show="showDocList1">
                               <vue-feather size="16px" type="x"></vue-feather>
                            </a>
                          </div>
                          <div class="file-upload-select" v-show="parseInt(ticket.reason) != 91">
                            <input
                              type="file"
                              name="file-upload-input"
                              accept="image/*,application/pdf"
                              @change="setImage"
                              @click="reset()"
                              class="file-upload-input"
                              ref="fileinput"
                            />
                            <div class="file-select-name">
                              {{ attachment.name }}
                            </div>
                            <div class="file-select-button">
                              <img
                                src="assets/images/file-upload-icon.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="note">
                          <p>
                            <span style="color: #f14c52">*</span> Size of the
                            document should not be more than 1MB <br />
                            <span style="color: #f14c52">*</span> File format
                            (jpeg or pdf) only <br />
                          </p>
                        </div>
                      </li>
                    </ul>
                    <div class="btn-holder t-center">
                      <button
                        type="button"
                        class="btn btn-blue"
                        @click="$router.back()"
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        class="btn btn-blue popup-link-holder"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div class="img-holder t-center wow fadeInRight va-top">
              <img src="assets/images/supp-img.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {
      reqforward : false,
      ticketid:'',
      ticketno: '',
      page: 1,
      replyMessage: "",
      attachment: {},
      url: process.env.VUE_APP_API_BASE_URL,
      showDocList1 : false,
      docType : ''
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  mounted() {
    if (this.$route.query.id) {
      this.listing();
    } else {
      this.$router.back();
    }
  },
  computed: {
    ticket() {
      try {
        if (
          this.store.ticketDetails.ticket &&
          this.store.ticketDetails.ticket.length
        ) {
          let t = this.store.ticketDetails.ticket[0];
          t.created = this.COMMON.formatDate(t.created);
          return t;
        } else {
          return {
            id: "",
            user_id: "",
            ticketnumber: "",
            reason: "",
            title: "",
            reason_text: "",
            title_text: "",
            comment: "",
            assigned_to: "",
            created: "",
          };
        }
      } catch {
        return {
          id: "",
          user_id: "",
          ticketnumber: "",
          reason: "",
          title: "",
          reason_text: "",
          title_text: "",
          comment: "",
          assigned_to: "",
          created: "",
        };
      }
    },
  },
  methods: {
    reset(){
        this.$refs.fileinput.value = ''
    },
    ticketReasonUser() {
      if (
        this.ticket.reason == "91" ||
        this.ticket.reason == "92" ||
        this.ticket.reason == "94"
      )
        return true;
      return false;
    },
    listing() {
      let formData = {
        ticket_id: this.$route.query.id,
      };
      this.store.getTicketDetails(formData, true,this)
    },
    ticketDetail(id,ticketnumber){
      let data={
          ticket_id: id,
          ticket: ticketnumber,
        }
      this.store.ticketdetaillist(data, this)
    },
    cancelTicket(id,ticketnumber){
      let data={
          ticket_id: id,
          ticket: ticketnumber,
        }
      this.store.cancelticket(data)
    },
    createThread() {
      let formData = new FormData();
      if (this.attachment.name && parseInt(this.ticket.reason) != 91) {
        formData.append("document", this.attachment);
      }
      if(parseInt(this.ticket.reason) == 91 && this.attachment.name){
        formData.append("document", this.attachment,this.docType.name+ '.'+ this.attachment.name.split('.')[this.attachment.name.split('.').length -1]);
        formData.append("type", 'user');
        formData.append("documenttype", this.docType.type);
      }
      formData.append("ticket_id", this.ticket.id);
      formData.append("message", this.replyMessage);
      this.store.createThread(formData, true);
      this.replyMessage = "";
      this.attachment = {};
    },
    setImage(e) {
      this.attachment = e.target.files[0];
      this.showDocList1 = false
    },
  }
};
</script>
